import React, { useEffect, useState, useContext, useCallback } from 'react';

import { locales } from './../../branding/locales/Locales';

const TranslateContext = React.createContext(null);

const TranslateProvider = ({ children, languages, defaultLanguage }) => {
	const [language, setLanguage] = useState(null);

	useEffect(() => {
		if (localStorage.getItem('locale') != null) {
			setLanguage(localStorage.getItem('locale'));
		} else {
			if (defaultLanguage != null) {
				handleSetLanguage(defaultLanguage);
			} else {
				let userLang = navigator.language || navigator.userLanguage;
				const indexInLanguages = languages.findIndex(
					(item) => item.key == userLang.substring(0, 2)
				);
				if (indexInLanguages > -1) {
					setLanguage(languages[indexInLanguages].key);
				} else {
					setLanguage('en');
				}
			}
		}
	}, []);

	const handleGetLanguage = useCallback(() => {
		return languages;
	}, [languages]);

	const handleSetLanguage = useCallback((value) => {
		document.documentElement.lang = value;
		setLanguage(value);
		localStorage.setItem('locale', value);
	}, []);

	return (
		language != null && (
			<TranslateContext.Provider
				value={{
					language: language,
					getLanguages: handleGetLanguage,
					setLanguage: handleSetLanguage,
				}}
			>
				{children}
			</TranslateContext.Provider>
		)
	);
};

const useTranslate = () => {
	let translateContext = useContext(TranslateContext);
	return translateContext;
};

const Translate = ({ value, hasToExist = false }) => {
	const { language } = useTranslate();
	const text = locales[language];
	if (hasToExist === true && text[value] == null) {
		return null;
	}
	return text[value] ?? value;
};

export { TranslateProvider, Translate, useTranslate };
