import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import _ from 'lodash';

import { theme as brandingTheme } from './branding/Branding';
import { TranslateProvider } from './components/translate/Translate';
import { availableLocales } from './branding/locales/Locales';

const theme = createTheme(
	_.merge(
		{},
		{
			components: {
				MuiButton: {
					styleOverrides: {
						root: {
							textTransform: 'none',
							minWidth: '11.5rem',
							height: '2.3rem',
							lineHeight: '3.125rem',
							verticalAlign: 'middle',
							paddingTop: '0px',
							paddingBottom: '0px',
						},
					},
				},
				MuiButtonBase: {
					defaultProps: {
						disableRipple: true,
					},
				},
				MuiSelect: {
					styleOverrides: {
						root: {
							textTransform: 'none',
						},
					},
				},
				MuiTextField: {
					defaultProps: {
						fullWidth: true,
					},
				},
			},
		},
		brandingTheme
	)
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<TranslateProvider languages={availableLocales}>
				<App />
			</TranslateProvider>
		</ThemeProvider>
	</React.StrictMode>
);
