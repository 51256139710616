import { RouterProvider, createBrowserRouter, createRoutesFromElements, Route, Link, Outlet, useLocation } from 'react-router-dom';

import { useTranslate } from './components/translate/Translate';
import { Routing } from './branding/Routing';
import { Text } from './components/text/Text';
import { availableLocales } from './branding/locales/Locales';
import { erdf, nsrfEN, nsrfGR, epanekEN, epanekGR, fundingOfInnovationEN, fundingOfInnovationGR, o7insurance } from './branding/Branding';
import logo from './branding/assets/logo.png';
import footerLogoEN from './../src/assets/footer_logo_en.png';
import footerLogoGR from './../src/assets/footer_logo_gr.png';
import './App.scss';

const Structure = () => {
	const usetranslate = useTranslate();

	const { pathname } = useLocation();

	const setLanguage = (value) => {
		usetranslate.setLanguage(value);
	};
	return (
		<>
			<header>
				<nav className="navbar" role="navigation">
					<div className="navbar-brand">
						<Link to="/">
							<img id="logo" src={logo} alt="logo" />
						</Link>
					</div>

					<ul className="nav navbar-top-links">
						{availableLocales.map((item) => (
							<li className={`languages ${usetranslate.language === item.key && 'selected'}`} key={item.key}>
								<button type="button" onClick={() => setLanguage(item.key)}>
									{item.label}
								</button>
							</li>
						))}
					</ul>
				</nav>
			</header>
			<main className={pathname === '/' ? 'home' : pathname.replace('/', '')}>
				<Outlet />
			</main>
			{pathname === '/' && (
				<footer>
					<img id="footer-logo" src={usetranslate.language == 'en' ? footerLogoEN : footerLogoGR} alt="footer_logo" />
					<div id="text">
						<Text as="span">footerTextPart1</Text>
						<Text as="a" href={o7insurance} target="_blank" rel="noreferrer">
							(O7Insurance)
						</Text>
						<Text as="span">footerTextPart2</Text>
						<Text as="a" href={erdf} target="_blank" rel="noreferrer">
							ERDF
						</Text>
						<Text as="span">footerTextPart3</Text>
						<Text as="a" href={usetranslate.language == 'en' ? epanekEN : epanekGR} target="_blank" rel="noreferrer">
							EPANEK
						</Text>
						<Text as="span">footerTextPart4</Text>
						<Text as="a" href={usetranslate.language == 'en' ? nsrfEN : nsrfGR} target="_blank" rel="noreferrer">
							NSRF
						</Text>
						<Text as="span">footerTextPart5</Text>
						<Text as="a" href={usetranslate.language == 'en' ? fundingOfInnovationEN : fundingOfInnovationGR} target="_blank" rel="noreferrer">
							fundingOfInnovation
						</Text>
						<Text as="span">footerTextPart6</Text>
					</div>
				</footer>
			)}
		</>
	);
};

export const App = () => {
	const routing = createBrowserRouter(
		createRoutesFromElements(
			<Route element={<Structure />}>
				<Route path="/" lazy={() => import(`./features/home/Routing`)} exact />
				{Routing}
			</Route>
		),
		{ basename: '/' }
	);

	return <RouterProvider router={routing} />;
};

export default App;
