import en from './en.json';
import el from './el.json';

const availableLocales = [
	{ key: 'en', label: 'EN' },
	{ key: 'el', label: 'EL' },
];

const locales = {
	en: en,
	el: el,
};

export { locales, availableLocales };
