import { Route } from 'react-router-dom';

export const Routing = [
	<Route
		path="signin"
		lazy={() => import(`./features/signin/Routing`)}
		key="signin"
	/>,
	<Route
		path="signup"
		lazy={() => import(`./features/signup/Routing`)}
		key="signup"
	/>,
	<Route
		path="signupsuccess"
		lazy={() => import(`./features/signupsuccess/Routing`)}
		key="signupsuccess"
	/>,
];
