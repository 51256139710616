import { Translate } from './../translate/Translate';

export const Text = ({
	as: Component = 'p',
	children,
	keyText = null,
	optional = true,
	...props
}) => {
	const text = Translate({
		value: keyText ?? children,
		hasToExist: !optional,
	});
	return text != null && <Component {...props}>{text}</Component>;
};
